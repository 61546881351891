import "./App.scss";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout.jsx";
import Error from "./shared/Error.jsx";
import ErrorHandler from "./pages/ErrorHandler/ErrorHandler";
import { ProvideAuth } from "./context/useAuth.js";
import { LanguageProvider } from "./context/useLanguage.js";
import Home from "./pages/Home/Home.jsx";
import Login from "./pages/Login/Login.jsx";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.jsx";
import OAuth2Redirect from "./pages/oauth2/OAuth2Redirect.jsx";
import SignUp from "./pages/SignUp/SignUp.jsx";
import EventDashboard from "./pages/EventDashboard/EventDashboard.jsx";
import AccountSettings from "./pages/AccountSettings/AccountSettings.jsx";
import FAQ from "./pages/FAQ/FAQ.jsx";
import ScheduleDemo from "./pages/ScheduleDemo/ScheduleDemo.jsx";
import LiveStream from "./pages/LiveStream/LiveStream.jsx";
import About from "./pages/About/About.jsx";
// import PreviewEventWithPerformances from './pages/Events/PreviewEventWithPerformances.jsx';
// import PreviewEvent from './pages/Events/PreviewEvent.jsx';
// import PromoterDashboard from './Promoter/PromoterDashboard.jsx';
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy.jsx";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions.jsx";
import LikedFollowed from "./pages/LikedFollowed-POSSIBLYDELETE/LikedFollowed.jsx";
import ContactUs from "./pages/ContactUs/ContactUs.jsx";
// import ViewEventPerformance from "./pages/Events/ViewEventPerformance.jsx";
import LoginInfo from "./pages/AccountSettings/LoginInfo/LoginInfo.jsx";
import MyOrders from "./pages/AccountSettings/MyOrders/MyOrders.jsx";
import ContactInfo from "./pages/AccountSettings/ContactInfo/ContactInfo.jsx";
import CloseAccount from "./pages/AccountSettings/CloseAccount/CloseAccount.jsx";
import PromoterLandingPage from "./pages/PromoterLandingPage/PromoterLandingPage.jsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Logout from "./components/Logout/Logout.jsx";
import EventPreview from "./pages/EventPreview/EventPreview.jsx";
import Performance from "./pages/Performance/Performance.jsx";
import { CartProvider } from "./context/useCart.js";
import Cart from "./pages/Cart/Cart.jsx";
import CheckoutRedirect from "./pages/Checkout/CheckoutRedirect.jsx";
import Tickets from "./pages/AccountSettings/Tickets/Tickets.jsx";
import Claims from "./pages/AccountSettings/Claims/Claims.jsx";
import ViewTickets from "./pages/ViewTickets/ViewTickets.jsx";
// Function to get the subdomain from the current URL

const queryClient = new QueryClient();

function getSubdomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split("/");

  // Assuming your main domain is eventlinx.com, check if subdomain exists
  if (parts.length > 2) {
    return parts[1]; // This should be the last part of the domain
  }
  return null; // No subdomain found
}

function getDynamicPath() {
  const path = window.location.pathname; // Get the path like "/test"
  console.log("path: ", path);

  return path.length > 1 ? path.slice(1) : path; // Remove the leading slash
}

// Create dynamic routes based on subdomain
const subdomain = getSubdomain();
const subdomainRoute = subdomain ? `/${subdomain}` : "/"; // Set a base route

console.log("subdomainRoute in App: ", subdomainRoute);

// Create dynamic routes based on the path after the domain
const dynamicPath = getDynamicPath();
/* 
FOR POSSIBLE FUTURE USE WHEN DEALING WITH PROMOTERS DYNAMIC ROUTES
const isSubdomainedRoute =
dynamicPath.startsWith('events') || dynamicPath.startsWith('performance');

const dynamicRoute = isSubdomainedRoute ? '/' : `/${dynamicPath}`;
*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "login", element: <Login /> },
      { path: "register", element: <SignUp /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "events", element: <EventDashboard /> },
      { path: "events/:eventId", element: <EventPreview /> },
      { path: "performance/:performanceId", element: <Performance /> },

      {
        path: "account-settings",
        element: <AccountSettings />,
        children: [
          { path: "login-info", element: <LoginInfo /> },
          { path: "tickets", element: <Tickets /> },
          { path: "my-orders", element: <MyOrders /> },
          { path: "claims", element: <Claims /> },
          { path: "contact-info", element: <ContactInfo /> },
          { path: "close-account", element: <CloseAccount /> },
          { path: "faq", element: <FAQ /> },
        ],
      },
      { path: "cart", element: <Cart /> },
      { path: "about", element: <About /> },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "terms-of-service", element: <TermsAndConditions /> },
      { path: "liked-followed", element: <LikedFollowed /> },
      { path: "contact-us", element: <ContactUs /> },
      { path: "faq", element: <FAQ /> },
      { path: "schedule-demo", element: <ScheduleDemo /> },
      { path: "stream/:eventId", element: <LiveStream /> },
      { path: "oauth2/redirect", element: <OAuth2Redirect /> },
      { path: "checkout/return", element: <CheckoutRedirect /> },
      { path: "view-tickets", element: <ViewTickets /> },

      {
        // path: `${dynamicRoute}`,
        path: ":path",
        element: <PromoterLandingPage subdomain={dynamicPath} />, // Custom promoter landing page
      },
      { path: "*", element: <Error /> },
    ],
  },
  { path: "logout", element: <Logout /> },
]);

/**
 * App is the root component of the application.
 * It represents the highest level component in the component hierarchy.
 *
 * @return {JSX.Element} The JSX Element representing the root component of the application.
 */
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LanguageProvider>
        <ProvideAuth>
          <CartProvider>
            <div className="App">
              <RouterProvider router={router} />
            </div>
          </CartProvider>
        </ProvideAuth>
      </LanguageProvider>
    </QueryClientProvider>
  );
}

export default App;
