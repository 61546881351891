import styles from "./EventDashboard.module.scss";
import rightArrow from "../../assets/right-arrow.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { useState, useRef, useEffect } from "react";
import { getEventList } from "../../utils/APIUtils";
import { useQuery } from "@tanstack/react-query";
import EventListCard from "../../components/EventListCard/EventListCard";
import EventsFilter from "../../components/EventsFilter/EventsFilter";
import useOutsideClick from "../../utils/useOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { getRoughLocation } from "../../utils/LocalizationUtils";

const SORT_OPTIONS = [
  { title: "Date", value: "date" },
  { title: "A-Z", value: "a-z" },
  { title: "Lowest Price", value: "lowest-price" },
  { title: "Latest", value: "latest" },
];

const FORMATS = ["All", "Live", "Stream", "Hybrid"];
const CATEGORIES = ["All", "Concert", "Festival", "Theatre", "Sports"];
const TYPES = [
  "All",
  "80's",
  "House",
  "Thechno",
  "Classical",
  "Urban",
  "Dance",
  "Hip-hop",
  "Jazz",
  "Blues",
  "Pop",
  "Metal",
  "Hardcore",
  "Future House",
];
const DATES = ["All", "This Weekend", "Date Range", "Weekdays"];

// const LOCATIONS =

export default function EventDashboard() {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [showFilter, setShowFilter] = useState(false);
  const [format, setFormat] = useState(FORMATS[0]);
  const [categories, setCategories] = useState(CATEGORIES[0]);
  const [types, setTypes] = useState([TYPES[1]]);
  const [date, setDate] = useState(DATES[0]);
  const [searchText, setSearchText] = useState("");
  const [location, setLocation] = useState(null);

  const filterRef = useRef(null);

  useOutsideClick(filterRef, () => setShowFilter(false));

  useEffect(() => {
    if (!location) {
      getRoughLocation().then((location) => {
        setLocation(location);
      });
    }
  }, []);

  const {
    status,
    data: eventsData,
    error: eventsError,
    isFetching: isEventsFetching,
  } = useQuery({
    queryKey: [
      "events",
      {
        format,
        categories,
        types,
        date,
        searchText,
        location,
        sortOption: sortOption.value,
      },
    ],
    queryFn: () =>
      getEventList({
        format,
        categories,
        types,
        date,
        searchText,
        location,
        sortBy: sortOption.value,
      }),
  });

  // TODO: ADD ERROR HANDLER FOR FETCHING DATA
  // Error handling: display the error message if something went wrong with the fetch.
  if (eventsError) {
    return <div>Error fetching events: {eventsError.message}</div>;
  }

  const handleSearch = () => {
    console.log("Search text: - Still not implemented", searchText);
  };

  const handleTypesChange = (value) => {
    if (value === "All") {
      setTypes([value]);
    } else {
      if (types.includes(value)) {
        setTypes((prevTypes) => {
          return prevTypes.filter((type) => type !== value);
        });
      } else {
        setTypes((prevTypes) => {
          if (prevTypes.includes("All")) {
            prevTypes.pop();
          }
          return [...prevTypes, value];
        });
      }
    }
  };

  const handleCategoriesChange = (value) => {
    if (value === "All") {
      setCategories([value]);
    } else {
      if (categories.includes(value)) {
        setCategories((prevCategories) => {
          return prevCategories.filter((category) => category !== value);
        });
      } else {
        setCategories((prevCategories) => {
          if (prevCategories.includes("All")) {
            prevCategories.pop();
          }
          return [...prevCategories, value];
        });
      }
    }
  };

  const handleLocationSet = (coord) => {
    setLocation(coord);
  };

  return (
    <div className={styles.eventDashboard}>
      <div className={styles.eventDashboardContainer}>
        <div className={styles.topContainer}>
          <div className={styles.searchContainer}>
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button onClick={handleSearch}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
          <div className={styles.pathContainer}>
            <a href="/">Home</a>
            <span>
              <img src={rightArrow} alt="" />
            </span>
            <a href="/events">Events</a>
          </div>
        </div>
        <section className={styles.dataSection}>
          <div
            className={`${styles.filtersContainer} ${
              showFilter ? styles.showFilter : ""
            }`}
            ref={filterRef}
          >
            <EventsFilter
              formats={FORMATS}
              selectedFormat={format}
              onFormatChange={setFormat}
              categories={CATEGORIES}
              selectedCategories={categories}
              onCategoryChange={handleCategoriesChange}
              types={TYPES}
              selectedTypes={types}
              onTypesChange={handleTypesChange}
              datesOptions={DATES}
              selectedDateOpt={date}
              onDateOptChange={setDate}
              onLocationSet={handleLocationSet}
            />
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.sortContainer}>
              <div className={styles.btnContainer}>
                <CustomButton
                  title="Filter"
                  variant={"eventFilter"}
                  clickHandler={() => setShowFilter(!showFilter)}
                />
              </div>
              {!isEventsFetching && (
                <span>
                  {eventsData.data.totalCount === 1
                    ? `${eventsData.data.totalCount} item found`
                    : `${eventsData.data.totalCount} items found`}
                </span>
              )}
              <CustomDropdown
                optionsObj={SORT_OPTIONS}
                title="Sort By: "
                onSelect={setSortOption}
                selectedOption={sortOption}
                variant="eventSort"
              />
            </div>
            <div className={styles.eventList}>
              {isEventsFetching ? (
                <div>Loading...</div>
              ) : (
                eventsData.data.events.map((event) => {
                  return <EventListCard event={event} key={event.id} />;
                })
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
