import styles from "./OrdersList.module.scss";
import OrderCard from "./OrderCard";
export default function OrdersList({
  ordersList,
  onCancelOrder,
  onRefundRequest,
}) {
  console.log("ordersList: ", ordersList);
  return (
    <ul className={styles.ordersList}>
      {ordersList?.map((order) => {
        return (
          <OrderCard
            key={order.transactionId}
            order={order}
            onCancelOrder={onCancelOrder}
            onRefundRequest={onRefundRequest}
          />
        );
      })}
    </ul>
  );
}
