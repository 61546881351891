import styles from "./ViewTickets.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getTicketsStatus } from "../../utils/APIUtils";
import TicketCard from "./TicketCard";
import { useLanguage } from "../../context/useLanguage";
import { getLanguageArray, getShowingLanguage } from "../../utils/CommonUtils";
import Spinner from "../../components/ModalContents/Spinner/Spinner";
import ResponseMessage from "../../components/ModalContents/ResponseMessage/ResponseMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPrint } from "@fortawesome/free-solid-svg-icons";

export default function ShowTickets() {
  console.log("Tickets Page");

  const location = useLocation();
  const navigate = useNavigate();

  const { ticketIds, origin } = location.state || null;

  const { language } = useLanguage();
  console.log("language => ", language);
  console.log("ticketIds => ", ticketIds);
  console.log("origin => ", origin);

  // Queries
  const {
    status: ticketsStatus,
    data: tickets,
    error: ticketsError,
    isFetching: isFetchingTickets,
  } = useQuery({
    queryKey: ["ticketsData", ticketIds],
    queryFn: () => getTicketsStatus(ticketIds),
    enabled: ticketIds && ticketIds?.length > 0,
  });

  console.log("tickets => ", tickets);
  const languageArray = tickets ? getLanguageArray(tickets[0].eventName) : [];

  const showingLanguage =
    tickets && languageArray.length > 0
      ? getShowingLanguage(languageArray, language)
      : language;

  if (!ticketIds) {
    return <>No tickets to show</>;
  }

  if (isFetchingTickets) {
    return (
      <div className={styles.whiteBackground}>
        <Spinner />
      </div>
    );
  }

  const navigateToOrigin = () => {
    console.log("navigateToOrigin origin => ", origin);

    if (origin) {
      navigate(origin);
    } else {
      navigate(-1);
    }
  };

  const printTickets = () => {
    // TODO: Implement print tickets function
    console.log("print tickets");
  };
  return (
    <div className={styles.viewTickets}>
      <div className={styles.viewTicketsContainer}>
        <div className={styles.viewTicketsHeader}>
          <button className={styles.returnBtn} onClick={navigateToOrigin}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <h2 className={styles.viewTicketsTitle}>View Tickets</h2>
          <button className={styles.printBtn} onClick={printTickets}>
            <FontAwesomeIcon icon={faPrint} />
            <span>Print Tickets</span>
          </button>
        </div>
        <ul className={styles.cardsContainer}>
          {tickets.map((ticket) => (
            <TicketCard
              key={ticket.ticketId}
              ticket={ticket}
              showingLanguage={showingLanguage}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
