import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  faUserCircle,
  faHouseChimney,
  faCircleXmark,
  faEllipsisVertical,
  faClipboardList,
  faFileImport,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AccountSettings.module.scss";
import useOutsideClick from "../../utils/useOutsideClick";
import { useAuth } from "../../context/useAuth";

/**
 * An enumeration representing the types of tabs.
 * @enum {string}
 * @readonly
 */
export const TAB_TYPES = Object.freeze({
  MY_ORDERS: { tab: "my-orders", label: "Orders", icon: faHouseChimney },
  TICKETS: {
    tab: "tickets",
    label: "Tickets",
    icon: faUserCircle, // TODO: Replace with tickets icon
  },
  CLAIMS: {
    tab: "claims",
    label: "Claims",
    icon: faUserCircle, // TODO: Replace with tickets icon
  },

  CONTACT_INFO: {
    tab: "contact-info",
    label: "Contact Information",
    icon: faClipboardList,
  },
  LOGIN_INFO: {
    tab: "login-info",
    label: "Login Information",
    icon: faFileImport,
  },
  CLOSE_ACCOUNT: {
    tab: "close-account",
    label: "Close Account",
    icon: faCircleXmark,
  },

  // FAQ: { tab: 'faq', label: 'FAQ', icon: faQuestionCircle },
});

/**
 * @description
 * This function is the main component for the account settings screen.
 * It renders the entire account settings screen including the navigation bar,
 * the different tabs, and the content of the selected tab.
 *
 * @return {JSX.Element} The JSX element representing the account settings screen.
 */
export default function AccountSettings() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isNavHidden, setIsNavHidden] = useState(true);
  const navRef = useRef();
  const togglerRef = useRef();
  const auth = useAuth();
  console.log("user in Account Settings: ", auth.user);

  useOutsideClick(navRef, (event) => {
    if (
      !isNavHidden &&
      togglerRef.current &&
      !togglerRef.current.contains(event.target)
    ) {
      setIsNavHidden(true);
    }
  });

  useEffect(() => {
    console.log("auth.user in Account Settings: ", auth.user);

    if (!auth.user) {
      console.log("User is not logged in");
      navigate("/login");
    } else {
      navigate("/account-settings/my-orders");
    }
  }, []);

  /**
   * @description
   * This function is the event handler for the Nav.OnSelect event in the AccountSettings component.
   * It is used to handle the selection of a tab in the account settings screen.
   *
   * @param {string} eventKey - The key of the selected tab.
   * @return {void} This function does not return anything.
   */
  const handleSelect = (e) => {
    console.log(e.currentTarget.name);
    setIsNavHidden(true);
    navigate(`/account-settings/${e.currentTarget.name}`);
  };

  /**
   * @description
   * Generates a class name for the navigation bar buttons.
   * If the tab is active, it adds the active class to the button.
   *
   * @param {string} tName - The name of the tab.
   * @return {string} The class name for the navigation bar button.
   */
  const btnClass = (tName) => {
    let className =
      pathname === `/account-settings/${tName}`
        ? `${styles.navBtn} ${styles.active}`
        : styles.navBtn;

    return className;
  };

  // Button component to be reused
  const Button = ({ icon, label, tab }) => {
    return (
      <button className={btnClass(tab)} name={tab} onClick={handleSelect}>
        <span className={styles.icon}>
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className={styles.label}>{label}</span>
      </button>
    );
  };

  const handleTogglerClick = () => {
    setIsNavHidden((prev) => !prev);
  };

  return (
    <div className={styles.accountSettings}>
      <button
        ref={togglerRef}
        className={styles.dropdownBtn}
        onClick={handleTogglerClick}
      >
        <span className={styles.icon}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </span>
        <span className={styles.label}>My Dashboard</span>
      </button>
      <div className={`${styles.sideBar} ${isNavHidden ? "" : styles.active}`}>
        <div className={styles.navContainer}>
          <nav ref={navRef}>
            <button className={styles.myBtn} onClick={handleTogglerClick}>
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faUserCircle} />
              </span>
              <span className={styles.label}>My Dashboard</span>
              <span className={styles.chevron}>
                <FontAwesomeIcon icon={faChevronUp} />
              </span>
            </button>
            {Object.values(TAB_TYPES)
              .filter(
                ({ tab }) =>
                  tab !== "login-info" ||
                  auth?.user?.authMethod !== "GoogleOAuth"
              )
              .map(({ icon, label, tab, img }) => (
                <Button
                  key={tab}
                  icon={icon}
                  label={label}
                  tab={tab}
                  img={img}
                />
              ))}
          </nav>
        </div>
      </div>
      <div style={{ flex: 1 }} className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}
