import {
  formatDateWithoutTime,
  getLanguageArray,
  getShowingLanguage,
  maskString,
} from "../../../utils/CommonUtils";
import styles from "./ClaimDetails.module.scss";
import { useLanguage } from "../../../context/useLanguage";
import { useModal } from "../../../context/useModal";
import CloseButton from "../../../components/CloseButton/CloseButton";
import { useNavigate } from "react-router-dom";
import StatusDetails from "./StatusDetails";

export default function ClaimDetails({
  eventName,
  ticketId,
  refundRequestId,
  performanceName,
  sectionName,
  typeName,
  transactionId,
}) {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const languageArray = getLanguageArray(eventName);
  const showingLanguage = getShowingLanguage(languageArray, language);
  const { closeModal } = useModal();

  const handleGoToClaims = () => {
    navigate("/account-settings/claims");
    closeModal();
  };

  const status = "pending"; // TODO: get status from API
  const requestId = "REQUEST_ID"; // TODO: get status from API
  const refundRequestDate = formatDateWithoutTime(new Date().toISOString()); // TODO: get status from API

  // Create a class name based on the status for the status tag
  const statusTagClass = `${styles.refundStatusTag} ${styles[status]}`;

  return (
    <div className={styles.claimDetails}>
      <div className={styles.claimDetailsContainer}>
        <section className={styles.claimDetailsHeader}>
          <div className={styles.claimDetailsHeaderLine}>
            <h2>Claim Details</h2>
            <div className={styles.closeButtonContainer}>
              <CloseButton onClick={closeModal} variant="noBG" />
            </div>
          </div>
          <button onClick={handleGoToClaims} className={styles.seeClaimsBtn}>
            See All Claims
          </button>
        </section>
        <section className={styles.details}>
          <div className={styles.detailsHeader}>
            <span className={styles.eventLabel}>Event</span>
            <span className={styles.eventName}>
              {eventName[showingLanguage]}
            </span>
          </div>
          <div className={styles.detailsTicket}>
            <div className={styles.detailsTicketLine}>
              <span className={styles.ticketId}>{`Ticket # ${maskString(
                ticketId
              )}`}</span>
              <span className={statusTagClass}>Refund under Review</span>
            </div>
            <div className={styles.requestContainer}>
              <span className={styles.requestLabel}>Request ID</span>
              <span className={styles.requestId}>{requestId}</span>
            </div>
          </div>
          <div className={styles.detailsPerformance}>
            <div className={styles.detailColumn}>
              <span className={styles.label}>Performance</span>
              <span className={styles.data}>
                {performanceName[showingLanguage]}
              </span>
            </div>
            <div className={styles.detailColumn}>
              <span className={styles.label}>Section</span>
              <span className={styles.data}>
                {sectionName[showingLanguage]}
              </span>
            </div>
            <div className={styles.detailColumn}>
              <span className={styles.label}>Type</span>
              <span className={styles.data}>{typeName[showingLanguage]}</span>
            </div>
          </div>
        </section>
        <section className={styles.statusContainer}>
          <h3>Status</h3>
          <StatusDetails
          // date={}
          // requestId={requestId}
          // refundRequestDate={refundRequestDate}
          />
        </section>
      </div>
    </div>
  );
}
