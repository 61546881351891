export const transformCheckoutData = (data) => {
  return {
    endTime: data.endTime,
    startTime: data.startTime,
    eventName: data.eventTitle,
    listOfTickets: data.listOfTickets,
    location: data.location,
    performanceId: data.performanceId,
    performanceName: data.performanceTitle,
    orderId: data.transactionId,
    ticketCount: data.ticketCount,
    transactionId: data.transactionId,
  };
};

export const transformCheckoutDataForCalendar = (data, showingLanguage) => {
  return {
    title: data.eventTitle[showingLanguage],
    description: data.performanceTitle[showingLanguage],
    location: data.location,
    startTime: data.startTime,
    endTime: data.endTime,
  };
};
