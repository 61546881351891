import styles from "./Timer.module.scss";
import { useState, useEffect, useRef } from "react";
import { useCart } from "../../context/useCart";
import { useModal } from "../../context/useModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ResponseMessage from "../ModalContents/ResponseMessage/ResponseMessage";

export default function Timer({ variant }) {
  const { cart, deleteCartMutate } = useCart();
  const { openModal } = useModal();

  <FontAwesomeIcon icon={faTriangleExclamation} />;
  const timerRef = useRef(null);
  const timeoutRef = useRef(null);
  const warningShownRef = useRef(false); // Prevents duplicate modal display
  const [cartExpirationDate, setCartExpirationDate] = useState(
    cart?.expiredAt || null
  );
  const [timeLeft, setTimeLeft] = useState(() =>
    calculateTimeLeft(cart?.expiredAt)
  );

  // Function to calculate the warning time (2 minutes before expiration)
  function calculateWarningTime(expiration) {
    if (!expiration) return null;
    const now = Date.now();
    const expirationTime = new Date(expiration).getTime();
    const timeLeft = expirationTime - now;
    // const warningTime = timeLeft - 1000 * 60 * 11; // Show warning 2 minutes before expiration
    const warningTime = timeLeft - 1000 * 60 * 2; // Show warning 2 minutes before expiration

    console.log("Calculated warning time:", warningTime, "ms from now");

    return warningTime > 0 ? warningTime : null; // Ensure the value is positive
  }

  // Function to calculate remaining time
  function calculateTimeLeft(expiration) {
    if (!expiration) return null;
    const now = Date.now();
    const diff = new Date(expiration).getTime() - now;
    return diff > 0
      ? {
          minutes: Math.floor((diff / 1000 / 60) % 60),
          seconds: Math.floor((diff / 1000) % 60),
        }
      : null;
  }

  // Effect to update cart expiration when the cart changes
  useEffect(() => {
    if (!cart?.expiredAt || cart?.expiredAt === cartExpirationDate) return;

    console.log("Updating cartExpirationDate:", cart?.expiredAt);
    setCartExpirationDate(cart?.expiredAt);
    warningShownRef.current = false; // Reset warning status when expiration changes
  }, [cart?.expiredAt]);

  // Effect to handle timer countdown and modal warning
  useEffect(() => {
    if (!cartExpirationDate) return;

    clearInterval(timerRef.current);
    clearTimeout(timeoutRef.current);

    const warningTime = calculateWarningTime(cartExpirationDate);

    // Ensure modal shows only once per expiration cycle
    if (!warningShownRef.current && warningTime !== null) {
      console.log("Setting timeout for warning modal in:", warningTime, "ms");
      timeoutRef.current = setTimeout(() => {
        console.log("Opening warning modal...");
        warningShownRef.current = true;
        openModal(
          <ResponseMessage
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Cart is about to expire"
            msg="Complete your purchase now to secure your tickets before they are released."
            isWarning={true}
          />,
          "communicationMessage"
        );
      }, warningTime);
    }

    timerRef.current = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(cartExpirationDate);
      setTimeLeft(updatedTimeLeft);

      if (!updatedTimeLeft) {
        console.log("Cart expired. Clearing interval.");
        clearInterval(timerRef.current);
        deleteCartMutate();
      }
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, [cartExpirationDate]);

  if (!timeLeft || !cart || timeLeft.minutes < 0) return null;

  return (
    <div className={`${styles.timer} ${styles[variant]}`}>
      <p>
        <span>Time left:</span> {timeLeft.minutes}:
        {String(timeLeft.seconds).padStart(2, "0")}
      </p>
    </div>
  );
}

function WarningContent() {
  return (
    <div className={styles.warningComponent}>
      <h3>Cart is about to expire</h3>
      <p>Please checkout your cart to avoid losing your tickets.</p>
    </div>
  );
}
