import styles from "./TicketCard.module.scss";
import QR from "../../components/QR/QR";
import {
  formatDateToAmericanStyle,
  getAddressFromLocation,
} from "../../utils/CommonUtils";
import { faCalendar, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TicketCard({ ticket, showingLanguage }) {
  console.log("ticket => ", ticket);
  console.log("showingLanguage => ", showingLanguage);

  return (
    <li className={styles.ticketCard}>
      <div className={styles.ticketCardContainer}>
        <div className={styles.qrContainer}>
          <h3 className={styles.qrTitle}>Event Access Code</h3>
          <h4 className={styles.ticketId}>Ticket ID #{ticket.ticketId}</h4>
          <QR
            ticketId={ticket.ticketId}
            patronUserId={ticket.ownedBy}
            status={ticket.ticketStatus}
          />
        </div>
        <div className={styles.sectionInfo}>
          <span>Sec:&nbsp;{ticket.sectionName[showingLanguage]}</span>
          <span>
            Type:&nbsp;
            {ticket.typeName[showingLanguage]}
          </span>
        </div>
        {/* <div className={styles.divider}></div> */}
        <div className={styles.detailsContainer}>
          <div className={styles.eventInfo}>
            <span className={styles.event}>
              {`EVENT: ${ticket.eventName[showingLanguage]}`}:
            </span>
            <span className={styles.performance}>
              {ticket.performanceName[showingLanguage]}
            </span>
          </div>
          <div className={styles.directionsInfo}>
            <div className={styles.date}>
              <FontAwesomeIcon icon={faCalendar} />
              <span className={styles.info}>
                {formatDateToAmericanStyle(ticket.startTime, true)}
              </span>
            </div>
            <div className={styles.location}>
              <FontAwesomeIcon icon={faLocationDot} />
              <span className={styles.info}>
                {getAddressFromLocation(ticket.location)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
