import styles from "./OrderCard.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { formatNumberToCurrency } from "../../../utils/CommonUtils";
import TicketInfo from "./TicketInfo";
import { useLanguage } from "../../../context/useLanguage";
import {
  getLanguageArray,
  getShowingLanguage,
} from "../../../utils/CommonUtils";
import {
  getStatusModalContent,
  transformOrderData,
} from "./ordersTransformation";
import CustomButton from "../../../components/CustomButton/CustomButton";
import {
  contactOrganizer,
  requestReceipt,
  // requestRefund,
} from "../../../utils/APIUtils";
import { useModal } from "../../../context/useModal";
import ContactOrganizer from "../../../components/ModalContents/ContactOrganizer/ContactOrganizer";
import Spinner from "../../../components/ModalContents/Spinner/Spinner";

// import ResponseMessage from "../../../components/ModalContents/ResponseMessage/ResponseMessage";

const FEEDBACK_MESSAGES = {
  CONTACT_ORGANIZER_SUCCESS: "Message sent successfully",
  CONTACT_ORGANIZER_ERROR: "Error sending message",
  REFUND_REQUEST_SUCCESS: "Refund request sent successfully",
  REFUND_REQUEST_ERROR: "Error sending refund request",
};

// Card that shows order details
export default function OrderCard({ order, onCancelOrder, onRefundRequest }) {
  const [showMore, setShowMore] = useState(false);
  const [receiptError, setReceiptError] = useState(null);
  const [cancelReason, setCancelReason] = useState("change-of-plans");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);
  const [messageObj, setMessageObj] = useState({});

  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  // TODO: Add error message to the UI, when we can not get the receipt

  console.log("order in OrderCard => ", order);
  // TODO: get the cancel reason enum from the backend

  // Changes the state to show more or less information
  const toggleShowMore = () => {
    setShowMore((prevValue) => !prevValue);
  };
  const transformedOrder = transformOrderData(order);
  const {
    orderId,
    orderDate,
    eventId,
    eventName,
    orderAmount,
    tickets,
    paymentIntentId,
    promoterStripeId,
    showCancelOrder,
    status,
    isCanceled,
    promoterId,
    startTime,
    isOrderRefunded,
    showViewAllReadyToScan,
    readyToScanTickets,
    languageArray,
  } = transformedOrder;

  // Define showing languange
  // Get selected language on the website
  const { language } = useLanguage();
  // Get array of languages from performance name
  console.log("eventName in OrderCard => ", eventName);

  // const languageArray = getLanguageArray(eventName);
  console.log("languageArray => ", languageArray);

  // Get showing language
  console.log("language => ", language);
  const showingLanguage = getShowingLanguage(languageArray, language);

  const handleSendMessage = async (messageObj) => {
    console.log("messageObj.messagePurpose => ", messageObj.messagePurpose);
    try {
      switch (messageObj.messagePurpose) {
        case "Refund Request":
          // Open the Spinner
          openModal(<Spinner />);

          // Create request object
          const requestObj = {
            transactionId: messageObj.orderId,
            reasonForRequest: messageObj.message,
            ticketIds: messageObj.selectedTickets,
            promoterId: messageObj.promoterId,
          };

          // Call API to request a refund
          await onRefundRequest(requestObj);
          // Handle the successful response
          openResponseMessage("success");
          break;
        case "General Inquiry":
          // Call API to contact the promoter
          openModal(<Spinner />);

          // Create request object
          const requestObj2 = {
            transactionId: messageObj.orderId,
            message: messageObj.message,
            title: messageObj.messageTitle,
            promoterId: messageObj.promoterId,
          };

          console.log("requestObj2 => ", requestObj2);

          // Call API to contact the promoter
          await contactOrganizer(requestObj2);
          // Handle the successful response
          openResponseMessage("success");

          break;
        default:
          break;
      }
    } catch (error) {
      // Handle the error response
      openResponseMessage("failure");
    }
  };

  const handleCancelMsg = () => {
    setMessageObj({});
    closeModal();
  };

  // Handles the Contact organizer button press
  const handleContactOrganizer = () => {
    openModal(
      <ContactOrganizer
        orderId={orderId}
        tickets={tickets}
        showingLanguage={showingLanguage}
        promoterId={promoterId}
        onSendMessage={handleSendMessage}
        messageObj={messageObj}
        onCancel={handleCancelMsg}
        onRefundRequest={onRefundRequest}
      />,
      "whiteFullWidth"
    );
  };

  const handleShowTickets = (ticketIds) => {
    // TODO: GET ALL READY TO SCAN TICKETS AND NAVIGATE TO TICKETS PAGE
    navigate("/view-tickets", {
      state: { ticketIds, origin: pathname },
    });
  };

  // Handles the receipt button press
  const handleReceipt = async () => {
    try {
      const receiptResponse = await requestReceipt(
        paymentIntentId,
        promoterStripeId
      );
      if (!receiptResponse.receipt) {
        setReceiptError("Receipt not found in response");
        return;
      }
      const receiptURL = receiptResponse.receipt;
      window.open(receiptURL, "_blank");
    } catch (error) {
      console.log("Error trying to request receipt: ", error);
      setReceiptError("Error trying to request receipt");
    }
  };

  const handleCancelOrder = async () => {
    onCancelOrder({ paymentIntentId, promoterStripeId, cancelReason });
  };

  const openResponseMessage = (status) => {
    openModal(getStatusModalContent(status, closeModal), "whiteFullWidth");
  };

  let className = styles.orderCard;
  if (status === "cancelled") {
    className = `${styles.orderCard} ${styles.canceled}`;
  }
  if (status === "refunded") {
    className = `${styles.orderCard} ${styles.refunded}`;
  }

  return (
    <li className={className}>
      <div className={styles.orderCardHeader}>
        <div className={styles.orderIdContainer}>
          <div className={styles.orderInfo}>
            <div className={styles.id}>
              <span className={styles.label}>Order #</span>
              <span className={styles.orderId}>{orderId}</span>
            </div>
            <div className={styles.status}>
              <span className={styles.dot}></span>
              <span className={styles.statusText}>{status}</span>
            </div>
          </div>
          {showCancelOrder && (
            <div className={styles.cancelOrderContainer}>
              <CustomButton
                clickHandler={handleCancelOrder}
                title="Cancel Order"
                variant="orderCardCancel"
              />
            </div>
          )}
        </div>
        <div className={styles.orderDetails}>
          <div className={styles.orderDate}>
            <span className={styles.label}>Order Placed</span>
            <span className={styles.info}>{orderDate}</span>
          </div>
          <div className={styles.orderTotal}>
            <span className={styles.label}>Order Total</span>
            <span className={styles.info}>
              {formatNumberToCurrency(orderAmount)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.event}>
        <div className={styles.eventContainer}>
          <span className={styles.label}>Event</span>
          <span className={styles.eventName}>{eventName[showingLanguage]}</span>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <CustomButton
          clickHandler={handleReceipt}
          title="Receipt"
          variant="orderCard"
        />
      </div>
      <div className={styles.showMoreBtnContainer}>
        <button className={styles.showMore} onClick={toggleShowMore}>
          {showMore ? (
            <div className={styles.showBtnContent}>
              <span className={styles.text}>Close</span>
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
          ) : (
            <div className={styles.showBtnContent}>
              <span className={styles.text}>Ticket Information</span>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          )}
        </button>
      </div>
      {showMore && (
        <div className={styles.showMoreContainer}>
          <ul className={styles.ticketsInfoContainer}>
            {tickets.map((ticket, index) => (
              <TicketInfo
                ticketInfo={ticket}
                showingLanguage={showingLanguage}
                isOrderRefunded={isOrderRefunded}
                onViewTicket={handleShowTickets}
                eventName={eventName}
                transactionId={orderId}
                key={`ticket-${index}`}
              />
            ))}
          </ul>
          {showViewAllReadyToScan && (
            <div className={styles.viewAllReadyBtnContainer}>
              <CustomButton
                clickHandler={() => handleShowTickets(readyToScanTickets)}
                title="View All Ready to Scan"
                variant="orderCardOutlinedGreen"
              />
            </div>
          )}
          {!isCanceled && (
            <div className={styles.contactContainer}>
              <span className={styles.contactText}>
                If you have any questions or need to request one or more
                refunds,{" "}
                <a
                  href="#"
                  onClick={handleContactOrganizer}
                  className={styles.contactLink}
                >
                  contact the event organizer here
                </a>
                .
              </span>
            </div>
          )}
        </div>
      )}
    </li>
  );
}
