import { useEffect, useRef, useState } from "react";

import styles from "./QR.module.scss";
import QRCodeStyling from "qr-code-styling";

import axios from "axios";
// import CustomButton from "../../../components/UI/Buttons/Button";

export default function QRPage({ ticketId, patronUserId, status }) {
  const ref = useRef(null);

  const submitHandler = async () => {
    ref.current.innerHTML = null;
    try {
      const data = { ticketId, patronUserId, status };
      console.log("data in QR => ", data);

      const qrCode = new QRCodeStyling({
        width: 170,
        height: 170,
        data: JSON.stringify(data),
        image: logoUrl,
        dotsOptions: {
          // color: res.data.status === "READY" ? "#4267b2" : "#ff0000", // Custom color
          type: "extra-rounded", // Rounded dots
          gradient: {
            type: "linear",
            rotation: 90,
            colorStops: [
              {
                offset: 0,
                color: status === "READY" ? "#27aae1" : "#ff0000",
              },
              {
                offset: 1,
                color: status === "READY" ? "#2a2c3a" : "#000000",
              },
            ],
          },
        },
        cornersSquareOptions: {
          type: "extra-rounded",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 5,
          hideBackgroundDots: true,
        },
      });

      qrCode.append(ref.current);
      // setStatus(res.data.status);
    } catch (err) {
      const error = new Error(err.response.data.error);
      error.status = err.status;
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    submitHandler();
  }, []);

  const logoUrl =
    "https://imagedelivery.net/AY07hQoC5O8QvC5wx2okIg/10f67ce6-2e2b-4b5a-f189-9e162ae98200/public"; // Replace with your logo URL

  return <div ref={ref} />;
}
