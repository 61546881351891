import styles from "./Claims.module.scss";

export default function Claims() {
  return (
    <div className={styles.claims}>
      <div className={styles.claimsContainer}>
        <h2>Claims</h2>
      </div>
    </div>
  );
}
